import {
  SCHEDULE_ITEM_SIZE,
  SCHEDULE_ROW_HEIGHT,
  TEAM_DEPARTMENT_ROW_ITEM_MARGIN,
} from '../../../+authenticated/+schedule/employee/schedule-employee-n-period/schedule-constants';
import { PayloadKeyType } from '../../../reducers/shared/entity.helper';
import { update } from '../../../reducers/store/schedule-shared.helper';

export const calculateDepartmentRowHeight = (data, totals) => {
  const shifts = Object.values(data ?? []);

  let rowHeight = SCHEDULE_ROW_HEIGHT;

  totals.forEach((total) => {
    const daySchedules = shifts.filter((schedule: any) => schedule.date === total.date);

    let maxDayHeight = TEAM_DEPARTMENT_ROW_ITEM_MARGIN + daySchedules.length * SCHEDULE_ITEM_SIZE;

    if (maxDayHeight < SCHEDULE_ROW_HEIGHT) {
      maxDayHeight = SCHEDULE_ROW_HEIGHT;
    }

    if (maxDayHeight > rowHeight) {
      rowHeight = maxDayHeight;
    }
  });

  return rowHeight;
};

export const groupDepartmentData = (data, totals) => {
  let departments = {};
  departments = update(departments, data.schedules, PayloadKeyType.SCHEDULES, 'occurrence_id', 'department_id');
  departments = update(
    departments,
    data.requiredShifts,
    PayloadKeyType.REQUIRED_SHIFTS,
    'occurrence_id',
    'department_id'
  );
  departments = update(departments, data.events, PayloadKeyType.EVENTS, 'id', 'department_id');

  const absences = [];
  data.absences.forEach((absence) => {
    const departmentIds = [...new Set(Object.values(absence.AbsenteeDay).map((day: any) => day.department_id))];
    departmentIds.forEach((departmentId: string) => {
      absences.push({ ...absence, departmentId });
    });
  });
  departments = update(departments, absences, PayloadKeyType.ABSENCE, 'id', 'departmentId');

  const departmentData = Object.values(departments).map((department: any) => ({
      ...department,
      eventsRowHeight: calculateDepartmentRowHeight(department.events, totals),
      requiredShiftsRowHeight: calculateDepartmentRowHeight(department.requiredShifts, totals),
    }));

  return Object.values(departmentData);
};
