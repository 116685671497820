import { LoadSchedulesSuccessAction, ScheduleModel, SchedulesLoadRequest } from '../../orm/schedule/schedule.model';
import { EmployeeRowData } from './schedule.n.model';

const ACTION_PREFIX = '[ScheduleN]';

export const scheduleNActionType = {
  LOAD_SUCCESS: `${ACTION_PREFIX} LOAD SUCCESS`,
  REMOVE: `${ACTION_PREFIX} REMOVE`,
  UPDATE: `${ACTION_PREFIX} UPDATE`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} UPDATE SUCCESS`,
  TRANSFER: `${ACTION_PREFIX} TRANSFER`,
  ASSIGN: `${ACTION_PREFIX} ASSIGN`,
  RESET: `${ACTION_PREFIX} RESET`,
  SET_PERIOD: `${ACTION_PREFIX} SET PERIOD`,
  CONSOLIDATE_DATA: `${ACTION_PREFIX} CONSOLIDATE DATA`,
};

export class ScheduleNAction {
  static loadSuccess(payload, requestData: SchedulesLoadRequest): LoadSchedulesSuccessAction {
    return {
      type: scheduleNActionType.LOAD_SUCCESS,
      payload,
      requestData,
    };
  }
  static setPeriod(minDate, maxDate) {
    return {
      type: scheduleNActionType.SET_PERIOD,
      payload: {
        minDate,
        maxDate,
      },
    };
  }

  static reset() {
    return {
      type: scheduleNActionType.RESET,
    };
  }
  static remove(userId: string, occurrenceId: string) {
    return {
      type: scheduleNActionType.REMOVE,
      payload: { userId, occurrenceId },
    };
  }

  static transfer(uniqueId, fromSchedule: any, toSchedule: any) {
    return {
      type: scheduleNActionType.TRANSFER,
      payload: { uniqueId, fromSchedule, toSchedule },
    };
  }

  static assign(uniqueId, schedule, newDate) {
    return {
      type: scheduleNActionType.ASSIGN,
      payload: { uniqueId, schedule, newDate },
    };
  }

  static update(uniqueId, schedule: ScheduleModel) {
    return {
      type: scheduleNActionType.UPDATE,
      payload: { uniqueId, schedule },
    };
  }

  static updateSuccess(uniqueId, response) {
    return {
      type: scheduleNActionType.UPDATE_SUCCESS,
      payload: { uniqueId, response },
    };
  }
}
