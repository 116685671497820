import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import { ScheduleAction } from '@reducers/orm/schedule/schedule.action';
import { ScheduleFilterPeriod } from '@reducers/page-filters/page-filters.model';
import { getLoanedShiftsFilter } from '@reducers/page-filters/page-filters.service';
import { getSelectedDepartmentIds } from '@reducers/selected-departments/selected-departments.service';
import { getArrayFromActionPayload, PayloadKeyType } from '@reducers/shared/entity.helper';
import { ScheduleNAction } from '@reducers/store/schedule/schedule.n.action';
import merge from 'lodash-es/merge';
import reduce from 'lodash-es/reduce';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { parseDate } from '../../../../shared/date.helper';
import { groupDepartmentData } from '../../../../shared/schedule/tasks/group-department-data';
import { groupEmployeeData } from '../../../../shared/schedule/tasks/group-rosters';
import { groupTeamData } from '../../../../shared/schedule/tasks/group-team-data';
import { getDayListWithoutTotals } from '../../employee/schedule-employee-period/schedule-employee-period.helper';
import { ScheduleHelperService } from '../schedule-helper.service';

@Injectable({ providedIn: 'root' })
export class NPeriodService {
  private period: ScheduleFilterPeriod;

  public isLoading = new BehaviorSubject<boolean>(true);

  public constructor(
    private scheduleHelperService: ScheduleHelperService,
    private store: Store<AppState>,
  ) {}

  public fetchSchedules(schedulePeriod: ScheduleFilterPeriod) {
    return combineLatest([
      of(schedulePeriod),
      this.store.select(getSelectedDepartmentIds),
      this.store.select(getLoanedShiftsFilter),
    ]).pipe(
      switchMap(([period, selectedDepartmentIds, loanedShiftsFilter]) =>
        this.scheduleHelperService.loadDataObs(
          period.minDate,
          period.maxDate,
          selectedDepartmentIds,
          loanedShiftsFilter,
        ),
      ),
      map((scheduleDataSet) => {
        if (!scheduleDataSet) {
          return null;
        }

        const totals = getDayListWithoutTotals({
          start: parseDate(this.period.minDate),
          end: parseDate(this.period.maxDate),
        });

        const dataSet = reduce(
          scheduleDataSet,
          (result, singleDataset) => {
            const empty = !!singleDataset && singleDataset?.result?.length > 0;
            return empty ? merge(result, singleDataset) : result;
          },
          {},
        );

        const schedules = getArrayFromActionPayload(PayloadKeyType.SCHEDULES, dataSet.entities);
        const requiredShifts = getArrayFromActionPayload(PayloadKeyType.REQUIRED_SHIFTS, dataSet.entities);
        const absences = getArrayFromActionPayload(PayloadKeyType.ABSENCE, dataSet.entities);
        const employeeData = {
          schedules,
          absences,
          availabilities: getArrayFromActionPayload(PayloadKeyType.AVAILABILITIES, dataSet.entities),
        };
        const teamData = {
          openShifts: getArrayFromActionPayload(PayloadKeyType.OPENSHIFTS, dataSet.entities),
          requiredShifts,
          schedules,
        };

        const departmentData = {
          schedules,
          events: getArrayFromActionPayload(PayloadKeyType.EVENTS, dataSet.entities),
          requiredShifts,
          absences,
        };

        return {
          dataSet,
          employeeData,
          teamData,
          departmentData,
          totals,
        };
      }),
    );
  }

  public processData(dataSet, employeeData, teamData, departmentData, totals) {
    this.store.dispatch(
      ScheduleNAction.loadSuccess(
        {
          scheduleData: groupEmployeeData(employeeData, totals),
          teamData: groupTeamData(teamData, totals),
          departmentData: groupDepartmentData(departmentData, totals),
        },
        {
          minDate: this.period.minDate,
          maxDate: this.period.maxDate,
        },
      ),
    );

    this.store.dispatch(ScheduleAction.loadScheduleDatasetSuccess(dataSet, this.period));
    this.isLoading.next(false);
  }

  public setPeriod(period: ScheduleFilterPeriod) {
    this.period = period;
    if (!period) {
      this.isLoading.next(false);
    }
  }

  public getPeriod() {
    return this.period;
  }
}
