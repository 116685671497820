import { PayloadKeyType } from '../../../reducers/shared/entity.helper';
import { calculateSingleItemRowHeight, update } from '../../../reducers/store/schedule-shared.helper';

export const groupTeamData = (data, totals) => {
  let teams = {};

  teams = update(teams, data.schedules, PayloadKeyType.SCHEDULES, 'occurrence_id', 'team_id');
  teams = update(teams, data.openShifts, PayloadKeyType.OPENSHIFTS, 'occurrence_id', 'team_id');
  teams = update(teams, data.requiredShifts, PayloadKeyType.REQUIRED_SHIFTS, 'occurrence_id', 'team_id');

  if (teams.hasOwnProperty(null)) {
    delete teams['null'];
  }

  const teamData = Object.values(teams).map((team: any) => ({
      ...team,
      openShiftsRowHeight: calculateSingleItemRowHeight(team.openShifts, totals),
      requiredShiftsRowHeight: calculateSingleItemRowHeight(team.requiredShifts, totals),
    }));

  return Object.values(teamData);
};

export const createNewModel = (groupById: string) => ({
    id: groupById,
    requiredShifts: {},
    openShifts: {},
    teamDays: {},
    schedules: {},
  });
