import { PayloadKeyType } from '../../../reducers/shared/entity.helper';
import { calculateScheduleRowHeight, update } from '../../../reducers/store/schedule-shared.helper';

export const groupEmployeeData = (data, totals) => {
  const { schedules, absences, availabilities } = data;

  let users = {};
  const mappedAvailabilities = availabilities.filter((availability) => availability.type);

  users = update(users, schedules, PayloadKeyType.SCHEDULES, 'occurrence_id');
  users = update(users, absences, PayloadKeyType.ABSENCE);
  users = update(users, mappedAvailabilities, PayloadKeyType.AVAILABILITIES, 'date');

  return Object.values(users).map((user: any) => {
    const userSchedules = Object.values(user.schedules);
    return {
      ...user,
      filteredSchedules: user.schedules,
      scheduleTeams: [...new Set(userSchedules.map((schedule: any) => schedule.team_id))],
      rowHeight: calculateScheduleRowHeight(user, totals),
    };
  });
};
